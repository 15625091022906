export function buildVehicleInspectionBooking (booking) {
    return {
        id: booking.id,
        startDate: booking.date,
        code: booking.code,
        cancellationLimitDate: booking.expiration_date,
        priceQty: booking.vehicleInspectionStationProduct.online_price,
        station: {
            id: booking.vehicleInspectionStation.id,
            slug: booking.vehicleInspectionStation.slug,
            name: booking.vehicleInspectionStation.name,
            description: booking.vehicleInspectionStation.description,
            address: booking.vehicleInspectionStation.address,
            email: booking.vehicleInspectionStation.email,
            phone: booking.vehicleInspectionStation.phone,
            timetable: booking.vehicleInspectionStation.timetable,
            timeZone: booking.vehicleInspectionStation.time_zone_name,
        },
        vehicle: {
            numberPlate: booking.vehicle.number_plate,
            brand: booking.vehicle.brand.name,
            model: booking.vehicle.model.name,
            vehicleType: booking.vehicle.body_type,
            fuelType: booking.vehicle.fuel_type,
        },
        productPrice: booking.vehicleInspectionStationProduct.online_price_without_commission,
        userCommissionPrice: booking.vehicleInspectionStationProduct.online_price_user_commission,
        productName: booking.vehicleInspectionStationProduct.name,
        isPostPayment: booking.vehicleInspectionStationProduct.is_post_payment,
        requiresAppointment: booking.vehicleInspectionStationProduct.requires_appointment,
        canceled: booking.cancelled,
        isCancelable: booking.isCancelable,
    }
}
