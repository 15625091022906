import Routes from '../routes/routes'
import { buildPostApi } from './helpers'
import { buildResult } from './converters/viatOrderData'
import { buildClientError } from 'commons/js/api/converters'
import { UnprocessableEntityError } from '../client/errors'

function mapParams () {
    return {}
}

function mapPayload ({ order, paymentIntentId }) {
    const shouldUseCreditCard = order.creditCard && order.creditCard.setupIntentId !== order.creditCard.id

    return {
        orderId: order.id,
        creditCardId: shouldUseCreditCard ? order.creditCard.id : null,
        setup_intent_id: order.creditCard && order.creditCard.setupIntentId,
        promo_code: order.promoCode ? order.promoCode.code : undefined,
        payment_intent: paymentIntentId,
    }
}

function buildError (error) {
    if (error instanceof UnprocessableEntityError) {
        const data = { ...error.response.data }
        return {
            ...buildClientError(error),
            status: error.response.status,
            data: {
                promoCode: data.promotional_code,
                clientSecret: data.client_secret,
            },
        }
    }

    return buildClientError(error)
}

export default buildPostApi({
    route: Routes.apiPayExistingUserViatOrder,
    mapParams,
    mapPayload,
    buildResult,
    buildError,
})
