import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { mapPaginator } from './converters/paginator'
import { buildWalletBeneficiary } from './converters/walletBeneficiary'

function mapParams ({ page, walletId, email }) {
    return { walletId, page, email }
}

function buildResult (data) {
    return mapPaginator(data, buildWalletBeneficiary)
}

export default buildGetApi({
    route: Routes.apiGetWalletBeneficiaries,
    buildResult,
    mapParams,
})
