/* istanbul ignore file */

import Routes from '../routes/routes'
import { buildPostApi } from './helpers'
import { buildResult } from './converters/viatOrderData'

function mapParams ({ productId }) {
    return { productId }
}

export default buildPostApi({
    route: Routes.apiCreatePagateliaOrder,
    mapParams,
    buildResult,
})
