export const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR'
export const FORBIDDEN_ERROR = 'FORBIDDEN_ERROR'
export const NOT_FOUND_ERROR = 'NOT_FOUND_ERROR'
export const UNPROCESSABLE_ENTITY_ERROR = 'UNPROCESSABLE_ENTITY_ERROR'
export const SERVER_ERROR = 'SERVER_ERROR'
export const INVALID_FORMAT_ERROR = 'INVALID_FORMAT_ERROR'
export const CONNECTION_ERROR = 'CONNECTION_ERROR'
export const TIMEOUT_ERROR = 'TIMEOUT_ERROR'
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR'

export const OPENING_MODE_VIAT_EXIT = 5
export const OPENING_MODE_VIAT_ENTRY_EXIT = 4
