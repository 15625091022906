export function buildPrivacy (privacy) {
    return {
        sendAdvertisingNotifications: privacy.send_advertising_notifications,
        profileForAdvertising: privacy.profile_for_advertising,
        shareAdvertisingDataWithThirdParties: privacy.share_advertising_data_with_third_parties,
    }
}

export function mapPrivacy (privacy) {
    return {
        send_advertising_notifications: privacy.sendAdvertisingNotifications,
        profile_for_advertising: privacy.profileForAdvertising,
        share_advertising_data_with_third_parties: privacy.shareAdvertisingDataWithThirdParties,
    }
}
