import Routes from '../routes/routes'
import { buildDelApi } from './helpers'
import { buildWalletBeneficiary } from './converters/walletBeneficiary'

export default buildDelApi({
    route: Routes.apiDeleteWalletBeneficiary,
    buildResult: buildWalletBeneficiary,
    mapParams: ({walletId, walletBeneficiary}) => ({
        walletId,
        idBeneficiary: walletBeneficiary.id,
    }),
    mapPayload: () => {},
})
