import Routes from '../routes/routes'
import { buildGetApi } from './helpers'

function mapParams ({ stationId, stationProductId, day }) {
    return {
        stationId,
        stationProductId,
        timestamp00: day,
    }
}

export default buildGetApi({
    route: Routes.apiGetVehicleInspectionStationOccupationIntraDay,
    mapParams,
})
