import Routes from '../routes/routes'
import { buildPutApi } from './helpers'
import { buildMobe } from './converters/mobe'

function mapParams ({ idMobe }) {
    return {
        idMobe,
    }
}

export default buildPutApi({
    route: Routes.apiUnbindVehicleToMobe,
    buildResult: buildMobe,
    mapParams,
})
