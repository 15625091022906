import {
    DEFAULT_VEHICLE_INSPECTION_RADIUS_SEARCH_RADIUS,
    ITV_GAS_LPG_CAR,
    ITV_DIESEL_CAR,
    ITV_GAS_LPG_VAN,
    ITV_DIESEL_VAN,
    ITV_ELECTRIC_CAR,
    ITV_MOTORCICLE,
    ITV_ELECTRIC_VAN,
    VEHICLE_TYPE_TOURING,
    VEHICLE_TYPE_VAN,
    VEHICLE_TYPE_MOTORCYCLE,
    FUEL_TYPE_GASOLINE,
    FUEL_TYPE_LPG,
    FUEL_TYPE_ELECTRIC,
    FUEL_TYPE_DIESEL,
} from 'commons/js/constants'

const MAP_VEHICLE_FILTER_TO_VEHICLE_AND_FUEL_TYPES = {
    [ITV_GAS_LPG_CAR]: {
        vehicle_types: [VEHICLE_TYPE_TOURING],
        fuel_types: [FUEL_TYPE_GASOLINE, FUEL_TYPE_LPG],
    },
    [ITV_DIESEL_CAR]: {
        vehicle_types: [VEHICLE_TYPE_TOURING],
        fuel_types: [FUEL_TYPE_DIESEL],
    },
    [ITV_ELECTRIC_CAR]: {
        vehicle_types: [VEHICLE_TYPE_TOURING],
        fuel_types: [FUEL_TYPE_ELECTRIC],
    },
    [ITV_GAS_LPG_VAN]: {
        vehicle_types: [VEHICLE_TYPE_VAN],
        fuel_types: [FUEL_TYPE_GASOLINE, FUEL_TYPE_LPG],
    },
    [ITV_DIESEL_VAN]: {
        vehicle_types: [VEHICLE_TYPE_VAN],
        fuel_types: [FUEL_TYPE_DIESEL],
    },
    [ITV_ELECTRIC_VAN]: {
        vehicle_types: [VEHICLE_TYPE_VAN],
        fuel_types: [FUEL_TYPE_ELECTRIC],
    },
    [ITV_MOTORCICLE]: {
        vehicle_types: [VEHICLE_TYPE_MOTORCYCLE],
        fuel_types: [],
    },
}

function getVehicleAndFuelTypes (vehicleFilter) {
    return MAP_VEHICLE_FILTER_TO_VEHICLE_AND_FUEL_TYPES[vehicleFilter] || { vehicle_types: [], fuel_types: [] }
}

export function mapSearchVehicleInspectionStation (search) {
    const vehicleAndFuelTypes = getVehicleAndFuelTypes(search.vehicle_filter)
    return {
        vehicle_types: vehicleAndFuelTypes.vehicle_types,
        fuel_types: vehicleAndFuelTypes.fuel_types,
        radius: DEFAULT_VEHICLE_INSPECTION_RADIUS_SEARCH_RADIUS,
        latitude: search.place.latitude,
        longitude: search.place.longitude,
        ...search.place.bounds,
    }
}

export function mapSearchVehicleInspectionProducts ({vehicleFilter, stationId}) {
    const vehicleAndFuelTypes = getVehicleAndFuelTypes(vehicleFilter)
    return {
        vehicle_types: vehicleAndFuelTypes.vehicle_types,
        fuel_types: vehicleAndFuelTypes.fuel_types,
        stationId,
    }
}

export function buildVehicleInspectionProduct (vehicleInpectionProduct) {
    return {
        ...vehicleInpectionProduct,
        onlinePrice: vehicleInpectionProduct.online_price_without_commission,
        totalPrice: vehicleInpectionProduct.online_price,
        originalPrice: vehicleInpectionProduct.original_price,
        idVehicleInspectionProduct: vehicleInpectionProduct.id_vehicle_inspection_product,
    }
}

export function buildVehicleInspectionStation (vehicleInpectionStation) {
    return {
        ...vehicleInpectionStation,
        products: buildVehicleInspectionProducts(vehicleInpectionStation.products),
    }
}

export function buildVehicleInspectionProducts (vehicleInpectionProducts) {
    return vehicleInpectionProducts.map(vehicleInpectionProduct => buildVehicleInspectionProduct(vehicleInpectionProduct))
}

export function buildVehicleInspectionStations (vehicleInpectionStations) {
    return vehicleInpectionStations.map(vehicleInpectionStation => buildVehicleInspectionStation(vehicleInpectionStation))
}
