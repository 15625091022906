import Routes from '../routes/routes'
import { buildPutApi } from './helpers'

function mapParams ({ bookingId, appointmentDate }) {
    return {
        bookingId,
        appointmentDate,
    }
}

export default buildPutApi({
    route: Routes.apiWorkshopBookingUpdateDates,
    mapParams,
})
