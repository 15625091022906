import Routes from '../routes/routes'
import { buildGetApi } from './helpers'

function mapParams (params = {}) {
    return {
        body_type: params.bodyType || null,
    }
}

function buildBrands (data) {
    return data.items
}

export default buildGetApi({
    route: Routes.apiGetVehicleBrands,
    buildBrands,
    mapParams,
})
