import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { buildProvince } from './converters/provinces'

export function buildProvinces (result) {
    return result.map(buildProvince)
}

export default buildGetApi({
    route: Routes.apiGetProvincesForShop,
    buildResult: buildProvinces,
})
