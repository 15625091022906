import Routes from '../routes/routes'

import { buildGetApi } from './helpers'

function mapParams ({ countryId }) {
    return { countryId }
}

export default buildGetApi({
    route: Routes.apiGetDepositTiers,
    buildResult: ({ increments, min_deposit_amount: min }) => ({
        increments,
        min,
    }),
    mapParams,
})
