import {
    buildBookingService,
    mapBookingService,
} from './booking-service'
import {
    buildWallets,
    buildWallet,
    mapFetchWalletParams,
} from './wallet'
import {
    buildUser,
    mapPreferences,
    buildPreferences,
} from './user'
import {
    buildEnvironmentalLabel,
} from './environmentalLabel'
import {
    buildClientError,
    buildRegistrationError,
    buildError,
    buildFieldError,
    buildBookingError,
    buildBookingAvailabilityError,
    buildLoginError,
    buildDeleteVehicleError,
    buildVehicleError,
} from './error'
import {
    mapAccount,
    mapSocialLogin,
    mapLogin,
} from './account'
import {
    mapContactForm,
} from './contact-form'
import {
    mapSetupIntent,
    buildSetupIntent,
} from './payment'
import {
    buildPrivacy,
    mapPrivacy,
} from './privacy'
import {
    buildParking,
    buildParkings,
    buildAirport,
    buildAirports,
    buildPois,
    mapSearchParkings,
    mapParking,
 } from './parking'
import {
    buildBookingProductAvailability,
    buildBookingAvailability,
    mapBookingAvailability,
} from './booking-availability'

import {
    mapPrebooking,
    mapBooking,
    buildBooking,
 } from './booking'

import {
    mapRotation,
    buildRotation,
 } from './rotation'
import {
    mapInvoices,
    mapInvoice,
} from './invoice'
import {
    buildVehicles,
    mapEditVehicle,
    buildVehicle,
    mapCreateVehicle,
} from './vehicle'
import {
    buildProvinces,
} from './provinces'
import {
    buildTowns,
} from './towns'
import {
    mapLanguages,
} from './languages'
import {
    buildWalletInvoiceData,
    mapWalletInvoiceData,
    mapWalletInvoiceDataUrlParams,
} from './walletInvoiceData'
import {
    buildActiveContracts,
} from './active-contracts'
import {
    buildInactiveContracts,
} from './inactive-contracts'
import {
    mapSearchVehicleInspectionStation,
    mapSearchVehicleInspectionProducts,
    buildVehicleInspectionStations,
    buildVehicleInspectionProducts,
} from './vehicleInspectionStation'
import {
    buildVehicleInspectionBooking,
} from './vehicleInspectionBooking'
import {
    mapSearchChargingPoint,
    buildChargingPoints,
} from './chargingPoints'
import {
    buildChargingPointBooking,
} from './chargingPointBooking'
import { buildDeposits } from './deposits'
import { buildTakseeTransaction } from './takseeTransaction'
export {
    mapInvoices,
    mapInvoice,
    buildVehicles,
    buildVehicle,
    mapEditVehicle,
    mapCreateVehicle,
    buildEnvironmentalLabel,
    mapSetupIntent,
    buildClientError,
    mapContactForm,
    buildBookingService,
    buildRegistrationError,
    buildError,
    buildFieldError,
    buildDeleteVehicleError,
    buildBookingAvailabilityError,
    buildLoginError,
    buildUser,
    buildParking,
    buildParkings,
    buildVehicleInspectionStations,
    buildAirport,
    buildAirports,
    buildPois,
    mapPreferences,
    buildPreferences,
    buildPrivacy,
    mapPrivacy,
    buildBookingProductAvailability,
    buildBookingAvailability,
    mapBookingAvailability,
    buildWallets,
    buildWallet,
    mapFetchWalletParams,
    buildSetupIntent,
    mapBookingService,
    mapAccount,
    mapSocialLogin,
    mapLogin,
    mapSearchParkings,
    mapSearchVehicleInspectionStation,
    mapPrebooking,
    buildBookingError,
    mapBooking,
    buildBooking,
    buildProvinces,
    buildTowns,
    mapWalletInvoiceData,
    mapWalletInvoiceDataUrlParams,
    buildWalletInvoiceData,
    buildActiveContracts,
    buildInactiveContracts,
    buildVehicleInspectionBooking,
    buildVehicleInspectionProducts,
    mapSearchVehicleInspectionProducts,
    mapSearchChargingPoint,
    buildChargingPoints,
    buildChargingPointBooking,
    mapParking,
    mapRotation,
    mapLanguages,
    buildRotation,
    buildDeposits,
    buildTakseeTransaction,
    buildVehicleError,
}
