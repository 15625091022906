import Routes from '../routes/routes'
import { buildPostApi } from './helpers'
import { getConstant } from 'commons/js/constants'

export function mapParams () {
    return {}
}

export function mapPayload ({ idWallet, setupIntent }) {
    return {
        default: true,
        exp_month: '',
        exp_year: '',
        setup_intent_id: setupIntent,
        id_wallet: idWallet,
        id_gateway: parseInt(getConstant('COUNTRY_PAYMENT_GATEWAY_ID')),
    }
}

export default buildPostApi({
    route: Routes.apiCreateCreditCard,
    mapPayload,
    mapParams,
})
