import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { mapPaginator } from './converters/paginator'
import { buildOrder } from './converters/viatOrder'

function mapParams ({ page }) {
    return { page }
}

function buildResult (data) {
    return mapPaginator(data, buildOrder)
}

export default buildGetApi({
    route: Routes.apiGetPagateliaOrdersData,
    buildResult,
    mapParams,
})
