import { buildVehicle } from './vehicle'
import { ID_WORKSHOP_PROVIDER_MIDAS, DATETIME_FORMAT } from 'commons/js/constants'
import { parseMoment, toParts, formatDate, formatTimestamp, toMillis } from 'commons/js/util/dateConverter'
import moment from 'moment-timezone'

function getKilometers ({ estimate }) {
    return estimate &&
        estimate.lines &&
        estimate.lines.reduce((km, { checkup_kilometers: kilometers }) => kilometers || km, null)
}

function buildTasks ({ estimate }) {
    if (estimate &&
        estimate.lines) {
        return estimate.lines.reduce((tasks, { sublines }) => {
            return [
                ...tasks,
                ...sublines.map(({ name }) => name),
            ]
        }, [])
    }
    return []
}

function buildProduct ({ estimate }) {
    if (estimate && estimate.lines && estimate.lines[0]) {
        return {
            name: estimate.lines[0].name,
            detail: estimate.lines[0].detail,
            priceQty: estimate.lines[0].price,
            id: estimate.lines[0].id_workshop_product,
            sublines: estimate.lines[0].sublines
                ? estimate.lines[0].sublines.map(({ name }) => name)
                : [],
        }
    }
    return null
}

function buildVehicleWithCriterias ({ vehicle, selectedCriteria, ...restBooking }) {
    return {
        ...buildVehicle(vehicle),
        criterias: selectedCriteria.map(({ description }) => description),
        description: restBooking.vehicle_version_description,
    }
}

export function buildWorkshopBookingCriteria (criteria) {
    return {
        description: criteria.description,
        mandatoryGroup: criteria.mandatory_group,
        elegible: criteria.user_elegible,
        group: criteria.option_group,
        ...criteria,
    }
}

export function buildWorkshopBookingCriterias (criterias) {
    const groups = []
    if (!criterias) {
        return groups
    }
    const items = criterias
        .filter(({ user_elegible: elegible }) => !!elegible)
        .map(buildWorkshopBookingCriteria)
    items.forEach(item => {
        const group = item.group
        if (!groups[group]) {
            groups[group] = {
                madandory: item.mandatory_group,
                criterias: [],
                id: item.group,
            }
        }
        groups[group].criterias.push(item)
    })
    return groups
}

export function buildWorkshopBooking (booking) {
    return {
        id: booking.id,
        cancelableLimitDate: booking.cancelableLimitDate,
        canceled: booking.canceled,
        code: booking.code,
        date: booking.date,
        price: booking.paid
            ? booking.price
            : booking.estimate && booking.estimate.price,
        product: buildProduct(booking),
        isCancelable: booking.isCancelable,
        paid: booking.paid,
        overrun: booking.overrun && {
            name: booking.overrun.description,
            priceQty: booking.overrun.amount,
        },
        workshop: booking.workshop && {
            name: booking.workshop.name,
            address: booking.workshop.address,
            timezone: booking.workshop.timezone,
        },
        km: getKilometers(booking),
        vehicle: buildVehicleWithCriterias(booking),
        tasks: buildTasks(booking),
    }
}

function getWorkshopProvider (id) {
    switch (id) {
        case ID_WORKSHOP_PROVIDER_MIDAS:
            return 'Midas'
    }
    return null
}

export function buildWorkshop (workshop) {
    return ({
        ...workshop,
        id: workshop.id,
        providerId: workshop.id_workshop_provider,
        name: workshop.name,
        address: workshop.address,
        timetable: workshop.timetable,
        latitude: workshop.lat,
        longitude: workshop.lon,
        provider: getWorkshopProvider(workshop.id_workshop_provider),
        priceQty: workshop.estimate_price,
    })
}

export function buildWorkshops (workshops) {
    return workshops.map(buildWorkshop)
}

export function buildVechicleVersions (versions) {
    return versions.map(({
        id_provider: providerId,
        id_external: externalId,
        description,
    }) => ({
        providerId,
        externalId,
        description,
    }))
}

const toTimestamp = (date, timeZone) => parseInt(formatTimestamp(moment.tz(formatDate(date, DATETIME_FORMAT), timeZone)))
const toDate = (timestamp, timeZone) => new Date(...toParts(parseMoment(toMillis(timestamp)).tz(timeZone)))
export function buildWorkshopBookingOccupation ({availableDates, timezone}) {
    const availability = {}
    availableDates.forEach(availableDate => {
        const parsedAvailableDate = moment(toDate(availableDate.start_date, timezone)).format('YYYY-MM-DD') + 'T00:00:00'
        const availableDay = toTimestamp(parsedAvailableDate, timezone)
        if (!availability[availableDay]) {
            availability[availableDay] = []
        }
        availability[availableDay].push(availableDate.start_date)
    })

    return {
        timezone,
        availability,
    }
}
