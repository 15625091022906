import Routes from '../routes/routes'
import { buildUser } from './converters/user'
import { buildGetApi } from './helpers'

function buildResult (user) {
    return buildUser(user)
}

export default buildGetApi({
    route: Routes.apiGetUser,
    buildResult,
})
