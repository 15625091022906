import { defineMessages } from 'react-intl'

export default defineMessages({
    sessionexpired: {
        defaultMessage: 'La sesión ha caducado',
    },
    internalerror: {
        defaultMessage: 'Se ha producido un error. Inténtelo más tarde',
    },
    invalidformat: {
        defaultMessage: 'El formato de la respuesta no es válido',
    },
})
