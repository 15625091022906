import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { buildCard } from './converters/creditCard'

function mapParams ({ id, paymentGatewayId }) {
    return {
        setUpIntentId: id,
        gatewayId: paymentGatewayId,
    }
}

export default buildGetApi({
    route: Routes.apiGetCreditCardInfo,
    buildResult: buildCard,
    mapParams,
})
