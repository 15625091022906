export function mapSetupIntent ({ paymentGatewayId }) {
    return {
        gatewayId: paymentGatewayId,
    }
}

export function buildSetupIntent (setupIntent) {
    return {
        clientSecret: setupIntent.client_secret,
    }
}
