import Routes from '../routes/routes'
import { buildPostApi } from './helpers'

export default buildPostApi({
    route: Routes.apiCreateDeposit,
    mapParams: ({ walletId }) => ({ walletId }),
    mapPayload: ({ walletId, qty }) => ({
        id_wallet: walletId,
        qty,
    }),
    buildResult: ({ qty }) => ({ qty }),
})
