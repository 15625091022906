import Routes from '../routes/routes'
import { buildGetApi } from './helpers'

export function buildResult (highways) {
    return highways.map(({id, highway_location: location, highway_name: name, type, url, description, path}) => ({
            id,
            location,
            name,
            type,
            url,
            description,
            path: path.map(([lat, lng]) => ({ lat, lng })),
        }))
}

export default buildGetApi({
    route: Routes.apiViatHighwayDiscounts,
    buildResult,
})
