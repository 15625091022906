export function buildProvinces (provinces) {
    return provinces.map(buildProvince)
}

export function buildProvince (province) {
    return {
        ...province,
        id: `${province.id}`,
    }
}
