import {
    CONTRACT_TYPE_BOOKING,
    CONTRACT_TYPE_SEASON_TICKET,
    CONTRACT_TYPE_SER_TICKET,
    CONTRACT_TYPE_PARKING_PASS,
    CONTRACT_TYPE_CHARGING_POINT_BOOKING,
    CONTRACT_TYPE_VEHICLE_INSPECTION_BOOKING_PASS,
    CONTRACT_TYPE_ROTATION,
    CONTRACT_TYPE_WORKSHOP_BOOKING,
} from 'commons/js/constants'

import {
    mapBooking,
    mapSerTicket,
    mapParkingPass,
    mapSeasonTicket,
    mapVehicleInspectionBooking,
    mapChargingPointBooking,
    mapRotation,
    mapWorkshopBooking,
} from './contracts'

function mapSeasonTicketContract (contract) {
    return {
        type: CONTRACT_TYPE_SEASON_TICKET,
        data: mapSeasonTicket(contract),
    }
}

function mapBookingContract (contract) {
    return {
        type: CONTRACT_TYPE_BOOKING,
        data: mapBooking(contract),
    }
}

function mapVehicleInspectionBookingsContract (contract) {
    return {
        type: CONTRACT_TYPE_VEHICLE_INSPECTION_BOOKING_PASS,
        data: mapVehicleInspectionBooking(contract),
    }
}

function mapParkingPassContract (contract) {
    return {
        type: CONTRACT_TYPE_PARKING_PASS,
        data: mapParkingPass(contract),
    }
}

function mapSerTicketContract (contract) {
    return {
        type: CONTRACT_TYPE_SER_TICKET,
        data: mapSerTicket(contract),
    }
}

function mapChargingPointBookingContract (contract) {
    return {
        type: CONTRACT_TYPE_CHARGING_POINT_BOOKING,
        data: mapChargingPointBooking(contract),
    }
}

function mapRotationContract (rotation) {
    return {
        type: CONTRACT_TYPE_ROTATION,
        data: mapRotation(rotation),
    }
}

function mapWorkshopBookingContract (booking) {
    return {
        type: CONTRACT_TYPE_WORKSHOP_BOOKING,
        data: mapWorkshopBooking(booking),
    }
}

export function buildActiveContracts (contracts) {
    const activeContracts = [
        ...contracts.seasonTicketContracts.map(mapSeasonTicketContract),
        ...contracts.bookings.map(mapBookingContract),
        ...contracts.bookedParkingPasses.map(mapParkingPassContract),
        ...contracts.serTickets.map(mapSerTicketContract),
        ...contracts.rotations.map(mapRotationContract),
        ...contracts.vehicleInspectionBookings.map(mapVehicleInspectionBookingsContract),
        ...contracts.chargingPointBookings.map(mapChargingPointBookingContract),
        ...contracts.workshopBookings.map(mapWorkshopBookingContract),
    ]

    return activeContracts
}
