import Routes from '../routes/routes'
import { buildDelApi } from './helpers'

function buildResult () {
    return true
}

function buildError (error) {
    const errorStatus = error.response ? error.response.status : error.name
    return {
        status: errorStatus,
    }
}

export default buildDelApi({
    route: Routes.apiDeleteCreditCard,
    buildResult,
    buildError,
})
