import Routes from '../routes/routes'
import { buildPostApi } from './helpers'

function buildResult () {
    return true
}

export default buildPostApi({
    route: Routes.apiAssociateCreditCard,
    buildResult,
})
