import Routes from '../routes/routes'
import { buildGetApi } from './helpers'

function buildResult (response) {
    return response.tfa_authentication_active
}

export default buildGetApi({
    route: Routes.apiGetTwoFaActiveMethod,
    buildResult,
})
