export function buildVehicle ({
    id,
    number_plate: numberPlate,
    model,
    brand,
    dgt_environmental_label_number: dgtEnvironmetalLabelNumber,
    auto_open_permission: autoOpenPermission,
    fuel_type: fuelType,
    body_type: bodyType,
}) {
    return {
        id,
        numberPlate,
        model: model,
        brand: brand,
        dgtEnvironmentalLabelNumber: dgtEnvironmetalLabelNumber,
        autoOpenPermission,
        fuelType,
        bodyType,
    }
}

export function buildBasicVehicle ({ id, brand, model, number_plate: numberPlate }) {
    return {
        id: id,
        brand: brand.name,
        model: model.name,
        numberPlate,
    }
}

export function mapEditVehicle ({
    id,
    modelId,
    fuelType,
    vehicleType,
}) {
    return {
        id: id,
        id_vehicle_model: modelId,
        fuel_type: fuelType,
        body_type: vehicleType,
    }
}

export function mapCreateVehicle ({
    numberPlate,
    modelId,
    autoOpenPermission,
    fuelType,
    dgtEnvironmetalLabelNumber,
    vehicleType,
}) {
    return {
        number_plate: numberPlate,
        dgt_environmental_label_number: dgtEnvironmetalLabelNumber,
        auto_open_permission: autoOpenPermission,
        id_vehicle_model: modelId,
        fuel_type: fuelType,
        body_type: vehicleType,
    }
}

export function buildVehicles (data) {
    return data.items.map(buildVehicle)
}
