import { error } from '../logger'
import loadParamsFromUrl from './loadFromUrl'
export default class UrlParams {
    constructor () {
        this.params = {}
    }

    set (name, value) {
        if (Array.isArray(value)) {
            for (const item in value) {
                const type = typeof item
                if (type !== 'string' && type !== 'number' && type !== 'bool') {
                    error('Not valid entry: ', typeof item)
                    return this
                }
            }
        }
        if (value !== null) {
            this.params[name] = value
        }
        return this
    }

    update (params) {
        if (typeof params !== 'object') {
            error('The type of params must be:', typeof Object, '. Not valid entry:', typeof params)
            return this
        }
        Object.keys(params).forEach(key => this.set(key, params[key]))
        return this
    }

    mergeWithUrlParams (urlParams) {
        this.loadFromUrl()
        if (urlParams.constructor.name !== 'URLSearchParams') {
            error('The type of params must be: URLSearchParams. Not valid entry: ', typeof urlParams)
            return this
        }
        for (const key of urlParams.keys()) {
            this.set(key, urlParams.get(key))
        }
        return this
    }

    loadFromUrl () {
        const query = loadParamsFromUrl().split('+').join(' ')
        const re = /[?&]?([^=]+)=([^&]*)/g
        let tokens = re.exec(query)
        while (tokens) {
            this.set(decodeURIComponent(tokens[1]), decodeURIComponent(tokens[2]))
            tokens = re.exec(query)
        }
        return this
    }

    getAll () {
        return { ...this.params }
    }

    toString (sorted = false) {
        let url = ''
        const arr = this.params
        const keys = Object.keys(arr)
        sorted && keys.sort()
        keys.forEach(function (key) {
            var param = arr[key]
            if (param != null) {
                url = url ? url + '&' : ''
                if (Array.isArray(param)) {
                    url += param.map((arrayElement) => `${key}[]` + '=' + arrayElement).join('&')
                } else if (param instanceof Object) {
                    error('Object type is not supported. Invalid entry: ', param)
                } else {
                    url += key + '=' + param
                }
            }
        })
        return encodeURI(url)
            .replace(/\+/g, '%2B')
            .replace(/:/g, '%3A')
    }
};
