import { TRADENAME } from 'commons/js/constants'

export function mapContactForm ({
    companyName,
    contact,
    email,
    knowUs,
    name,
    phone,
    address,
    province,
    employees,
    source,
    reCaptcha = null,
    acceptConditionsContactForm,
    comment = '',
    fleetName,
    fleetNumber,
    parkingName,
    parkingLotsNumber,
}) {
    return {
        company: companyName,
        email,
        know_us: knowUs,
        name,
        phone,
        address,
        province,
        employees,
        site: TRADENAME,
        comment,
        contact,
        landing: source,
        reCaptcha,
        accept_contact_conditions: acceptConditionsContactForm,
        fleetName,
        fleetNumber,
        parkingName,
        parkingLotsNumber,
    }
}
