import Routes from '../routes/routes'
import { buildPostApi } from './helpers'
import { buildTrailer } from './converters/trailer'

export default buildPostApi({
    route: Routes.apiCreateTrailer,
    mapPayload: ({ trailer }) => ({ number_plate: trailer.numberPlate }),
    mapParams: () => {},
    buildResult: buildTrailer,
})
