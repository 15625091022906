import { buildChargingPoint } from './chargingPoints'

export function buildChargingPointBooking (booking) {
    return {
        ...booking,
        startDate: booking.start_date,
        endDate: booking.end_date,
        estimatedTotalQty: booking.estimated_total_qty,
        canceled: booking.cancelled,
        vehicle: {
            numberPlate: booking.vehicle.number_plate,
            brand: booking.vehicle.brand.name,
            model: booking.vehicle.model.name,
            vehicleType: booking.vehicle.body_type,
            fuelType: booking.vehicle.fuel_type,
        },
        chargingPoint: {
            ...buildChargingPoint(booking.chargingPoint),
        },
    }
}
