import Routes from '../routes/routes'
import { buildWallet } from './converters/wallet'
import { buildGetApi } from './helpers'

function mapParams ({ id }) {
    return { idWallet: id }
}

export default buildGetApi({
    route: Routes.apiRequestWalletData,
    buildResult: buildWallet,
    mapParams,
})
