import Routes from '../routes/routes'
import { mapWorkshopPreferences, buildWorkshopPreferences } from './converters/wallet'

import { buildPutApi } from './helpers'

function mapParams (params) {
    return { walletId: params.idWallet }
}

export default buildPutApi({
    route: Routes.apiUpdateWalletPreferences,
    buildResult: buildWorkshopPreferences,
    mapPayload: mapWorkshopPreferences,
    mapParams,
})
