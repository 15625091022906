import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { mapPaginator } from './converters/paginator'
import { buildWhitelistedNumberPlate } from './converters/whitelistedNumberPlate'

function buildResult (data) {
    return mapPaginator(data, buildWhitelistedNumberPlate)
}

export default buildGetApi({
    route: Routes.apiGetPagedCorporateWhitelistedNumberPlates,
    buildResult,
    mapParams: ({ id, page, search, perPage = 15 }) => ({
        walletId: id,
        text_to_search: search,
        page,
        'per-page': perPage,
    }),
})
