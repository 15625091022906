import UrlParams from './UrlParams.js'
import defaultLanguage from 'commons/js/locale'
import defaultCountry, { ESP } from 'commons/js/country'
import { FLAVOR } from 'commons/js/constants'
import { warn } from 'commons/js/util/logger'

const API_HOST = process.env.API_HOST || ''

const UrlTo = {

    setRoutes (countryRoutes, apiRoutes) {
        UrlTo.countryRoutes = countryRoutes
        UrlTo.apiRoutes = apiRoutes
    },

    setUrlBase (base) {
        UrlTo.base = base
    },

    getApi (path, params) {
        const route = this.getFrom(path, params, UrlTo.apiRoutes)
        if (!route) {
            warn('Route not found', path, 'in api')
            return API_HOST + '/' + path
        }
        return API_HOST + route
    },

    get (path, params, lang = defaultLanguage, selectedCountry = defaultCountry, flavor = FLAVOR) {
        const country = Object.keys(UrlTo.countryRoutes).includes(selectedCountry)
            ? selectedCountry
            : ESP

        let route = this.getFrom(flavor + '/' + country + '/' + path, params, UrlTo.countryRoutes[country][lang])

        if (!route) {
            route = this.getFrom(flavor + '/' + path, params, UrlTo.countryRoutes[country][lang])
        }
        if (!route) {
            route = this.getFrom(country + '/' + path, params, UrlTo.countryRoutes[country][lang])
        }
        if (!route) {
            route = this.getFrom(path, params, UrlTo.countryRoutes[country][lang])
        }

        if (!route) {
            warn('Route not found', path, 'in', lang)
            return '/' + path
        }
        return route
    },

    /* eslint max-depth: ["error", 5] */
    getFrom (path, params, routes) {
        for (const lRoute in routes) {
            if (lRoute === path) {
                let myRoute = routes[lRoute]['route']
                var routeParams = myRoute.match(/:(\w|-)+/gi)
                for (const param in routeParams) {
                    if (params) {
                        const paramValue = params[param] ? params[param] : params[routeParams[param].substring(1)]
                        if (paramValue) {
                            myRoute = myRoute.replace(routeParams[param], paramValue)
                        }
                    }
                }
                const getParams = new UrlParams()
                for (const param in params) {
                    if ((routeParams === null) || !routeParams.includes(':' + param)) {
                        getParams.set(param, params[param])
                    }
                }
                const get = getParams.toString()
                return '/' + myRoute + (get ? '?' + get : '')
            }
        }
        return null
    },

    getUrlIntoLang (path, lang) {
        const { regex, route } = UrlTo.getUrlLangRegex(window.location.pathname)
        const allParams = path.match(regex)
        const params = []

        for (const param in allParams) {
            if (param !== 'index' && param !== 'input' && param !== '0') { params.push(allParams[param]) }
        }

        return UrlTo.get(route, params, lang)
    },

    getActualUrlIntoLang (lang) {
        return UrlTo.getUrlIntoLang(window.location.pathname, lang)
    },

    getUrlLangRegex (path) {
        const langRoutes = UrlTo.countryRoutes[defaultCountry]
        for (const lang in langRoutes) {
            for (const lRoute in langRoutes[lang]) {
                const routePattern = langRoutes[lang][lRoute].replace(/:(\w|-)+/gi, '([\\w|-]+)')
                const regex = new RegExp(routePattern)
                if (path.match(regex)) {
                    return {
                        regex: regex,
                        route: lRoute,
                    }
                }
            }
        }
    },

    getParams (path) {
        const re = /<(\w+).*?>/gmi
        const params = []
        let match

        while ((match = re.exec(path)) !== null) {
            if (match.index === re.lastIndex) {
                re.lastIndex++
            }
            params.push(match[1])
        }

        return params
    },

    getBase (path, params, lang) {
        return (UrlTo.base ? UrlTo.base : '') + UrlTo.get(path, params, lang)
    },

    appendUrlParams (link, tags = null) {
        if (!tags) {
            return link
        }
        const url = new URL(link)
        tags.map((tag) => url.searchParams.append(tag.key, tag.value))
        return url.toString()
    },
}

UrlTo.countryRoutes = []
UrlTo.apiRoutes = []

export default UrlTo
