import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { mapPaginator } from './converters/paginator'
import { buildTrailer } from './converters/trailer'

function buildTrailers (data) {
    return mapPaginator(data, buildTrailer)
}

export default buildGetApi({
    route: Routes.apiGetPagedTrailers,
    mapParams: ({ page, numberPlate = null }) => ({ page, number_plate: numberPlate }),
    buildResult: buildTrailers,
})
