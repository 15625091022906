import Routes from '../routes/routes'
import { buildGetApi } from './helpers'

function mapParams ({ walletId }) {
    return { walletId }
}

export default buildGetApi({
    route: Routes.apiRequestUserPricePerWallet,
    buildResult: ({ price_qty: qty }) => qty,
    mapParams,
})
