import Routes from '../routes/routes'
import { buildWorkshopBookingCriterias } from './converters/workshopBooking'
import { buildPutApi } from './helpers'
import { UnprocessableEntityError } from '../client/errors'
import { buildClientError } from 'commons/js/api/converters'

function mapParams ({ bookingId }) {
    return { bookingId }
}

function mapPayload ({ products }) {
    return products.map(({ productId, options }) => {
        if (options) {
            return {
                id_workshop_product: productId,
                options,
            }
        } else {
            return {
                id_workshop_product: productId,
            }
        }
    })
}

function buildError (error) {
    if (error instanceof UnprocessableEntityError) {
        const data = { ...error.response.data }
        return {
            ...buildClientError(error),
            status: error.response.status,
            workshopCriteria: buildWorkshopBookingCriterias(data.workshopCriteria),
            notElegibleWorkshopCriteria: data.workshopCriteria
                ? data.workshopCriteria.filter(({ user_elegible: elegible }) => !elegible)
                : [],
        }
    }

    return buildClientError(error)
}

export default buildPutApi({
    route: Routes.apiWorkshopBookingUpdateProductPath,
    mapParams,
    mapPayload,
    buildError,
})
