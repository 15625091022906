import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { buildResult } from './converters/viatOrderData'

function mapParams ({ id }) {
    return ({ id })
}

export default buildGetApi({
    route: Routes.apiGetPagateliaOrderData,
    mapParams,
    buildResult,
})
