import Routes from '../routes/routes'
import { buildPostApi } from './helpers'
import { buildWalletBeneficiary } from './converters/walletBeneficiary'

export default buildPostApi({
    route: Routes.apiCreateWalletBeneficiary,
    mapPayload: ({ walletBeneficiary }) => ({ email: walletBeneficiary.email }),
    mapParams: ({ walletId }) => ({ walletId }),
    buildResult: buildWalletBeneficiary,
})
