import {
    DEFAULT_CHARGING_POINTS_SEARCH_RADIUS,
} from 'commons/js/constants'

export function mapSearchChargingPoint (search) {
    return {
        radius: DEFAULT_CHARGING_POINTS_SEARCH_RADIUS,
        latitude: search.place.latitude,
        longitude: search.place.longitude,
        ...search.place.bounds,
    }
}

export function buildChargingPoint (point) {
    return {
        id: point.id,
        name: point.name,
        timetables: point.timeTables,
        description: point.description,
        address: point.address,
        priceTime: point.price_time,
        priceEnergy: point.price_energy,
        pricePerPlug: point.price_per_plug,
        medias: getFirstImageFromMedia(point.medias),
        plugs: point.plugs.map((plug) => ({
            id: plug.id,
            name: plug.name,
            slug: plug.slug,
            speedChargingType: plug.speed_charging_type,
        })),
        longitude: point.longitude,
        latitude: point.latitude,
        timeZone: point.time_zone_name,
        power: point.power,
        currency: point.currency.code,
    }
}

export function buildChargingPoints (vehicleInpectionStations) {
    return vehicleInpectionStations.map(vehicleInpectionStation => buildChargingPoint(vehicleInpectionStation))
}

function getFirstImageFromMedia (medias) {
    if (medias && medias.length > 0) {
        return {urlRegularSize: medias[0].url_regular_size}
    }
    return null
}
