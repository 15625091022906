export function buildUser (user) {
    return {
        id: user.id,
        firstName: user.name,
        lastName: user.lastname,
        phone: user.phone,
        email: user.email,
        preferences: user.preferencesElparking && buildPreferences(user.preferencesElparking),
        deleted: !!user.deleted_at,
    }
}

export function buildPreferences (preferences) {
    return {
        ticketNotification: preferences.ticket_notification,
        emailLanguage: preferences.email_language,
        autoDeposit: preferences.auto_deposit,
        autoDepositAmount: preferences.auto_deposit_amount,
        autoDepositThreshold: preferences.auto_deposit_threshold,
    }
}

export function mapPreferences (preferences) {
    return {
        ticket_notification: preferences.ticketNotification,
        email_language: preferences.emailLanguage,
    }
}
