import * as client from './client'
import Routes from '../routes/routes'
import country from 'commons/js/country'

import updateVehicleKm from './updateVehicleKm'
import login from './login'
import getDepositTiers from './getDepositTiers'
import socialLogin from './socialLogin'
import getUser from './getUser'
import getUserExists from './getUserExists'
import getViatParkingsByCountry from './getViatParkingsByCountry'
import getViatHighwayDiscounts from './getViatHighwayDiscounts'
import getOccupation from './getOccupation'
import getOccupationHours from './getOccupationHours'
import getViatOrderData from './getViatOrderData'
import getGlobalConfig from './getGlobalConfig'
import getCreditCards from './getCreditCards'
import getCreditCardInfo from './getCreditCardInfo'
import getUserWallets from './getUserWallets'
import getViatProducts from './getViatProducts'
import createCreditCard from './createCreditCard'
import payNewUserViatOrder from './payNewUserViatOrder'
import payExistingUserViatOrder from './payExistingUserViatOrder'
import createViatOrder from './createViatOrder'
import updateViatOrder from './updateViatOrder'
import confirmPaymentIntent from './confirmPaymentIntent'
import getViatOrders from './getViatOrders'
import cancelViatOrder from './cancelViatOrder'
import getProvincesForShop from './getProvincesForShop'
import bindWalletToMobe from './bindWalletToMobe'
import bindVehicleToMobe from './bindVehicleToMobe'
import unbindVehicleToMobe from './unbindVehicleToMobe'
import getMobes from './getMobes'
import getTolls from './getTolls'
import getPagedWalletBeneficiaries from './getPagedWalletBeneficiaries'
import getEnabledMobes from './getEnabledMobes'
import unassignMobe from './unassignMobe'
import createDeposit from './createDeposit'
import cancelWorkshopBooking from './cancelWorkshopBooking'
import getWorkshopBooking from './getWorkshopBooking'
import setWorkshopBookingProduct from './setWorkshopBookingProduct'
import getSuitableBookingWorkshops from './getSuitableBookingWorkshops'
import assignWorkshopToBookingWorkshop from './assignWorkshopToBookingWorkshop'
import setWorkshopBookingVehicle from './setWorkshopBookingVehicle'
import requestBrands from './requestBrands'
import getTwoFaActiveMethod from './getTwoFaActiveMethod'
import activeTwoFaActiveEmailMethod from './activeTwoFaActiveEmailMethod'
import deactivateTwoFaActiveEmailMethod from './deactivateTwoFaActiveEmailMethod'
import getFineTowns from './getFineTowns'
import getVehicles from './getVehicles'
import updateBookingVehicle from './updateBookingVehicle'
import getBookingAvailabilityUpdateDates from './getBookingAvailabilityUpdateDates'
import updateBookingDates from './updateBookingDates'
import getWorkshopBookingOccupation from './getWorkshopBookingOccupation'
import confirmWorkshopBooking from './confirmWorkshopBooking'
import setWorkshopBookingDate from './setWorkshopBookingDate'
import updateUserData from './updateUserData'
import getPagedVehicles from './getPagedVehicles'
import getWalletPreferences from './getWalletPreferences'
import getAutoDepositTiers from './getAutoDepositTiers'
import updateWalletPreferences from './updateWalletPreferences'
import getWallet from './getWallet'
import associateCreditCard from './associateCreditCard'
import uploadVehicleCsvFile from './uploadVehicleCsvFile'
import uploadTrailerCsvFile from './uploadTrailerCsvFile'
import getAdvancedVehicleAdmin from './getAdvancedVehicleAdmin'
import createTrailer from './createTrailer'
import getPagedTrailers from './getPagedTrailers'
import deleteTrailer from './deleteTrailer'
import deleteCreditCard from './deleteCreditCard'
import getWalletMovements from './getWalletMovements'
import uploadWalletBeneficiaryCsvFile from './uploadWalletBeneficiaryCsvFile'
import createWalletBeneficiary from './createWalletBeneficiary'
import deleteWalletBeneficiary from './deleteWalletBeneficiary'
import getUserPricePerWallet from './getUserPricePerWallet'
import updateCorporatePlatesWhitelistEnabled from './updateCorporatePlatesWhitelistEnabled'
import getPagedCorporateWhitelistedNumberPlates from './getPagedCorporateWhitelistedNumberPlates'
import deleteCorporateWhitelistedNumberPlate from './deleteCorporateWhitelistedNumberPlate'
import createCorporateWhitelistedNumberPlate from './createCorporateWhitelistedNumberPlate'
import uploadNumberPlateWhitelistCsvFile from './uploadNumberPlateWhitelistCsvFile'
import getWalletBeneficiariesAll from './getWalletBeneficiariesAll'
import sendMovementsToEmail from './sendMovementsToEmail'
import deleteAllCorporateWhitelistedNumberPlates from './deleteAllCorporateWhitelistedNumberPlates'

import {
    buildParking,
    buildParkings,
    buildVehicleInspectionStations,
    buildAirport,
    buildAirports,
    buildBookingAvailability,
    buildWallet,
    mapFetchWalletParams,
    buildError,
    buildPrivacy,
    buildDeleteVehicleError,
    buildBookingAvailabilityError,
    buildSetupIntent,
    buildPois,
    mapBookingAvailability,
    mapAccount,
    mapSearchParkings,
    mapSearchVehicleInspectionStation,
    mapContactForm,
    mapPreferences,
    buildPreferences,
    mapPrebooking,
    mapSetupIntent,
    mapBooking,
    buildBooking,
    buildBookingError,
    mapInvoices,
    buildVehicles,
    mapEditVehicle,
    buildVehicle,
    mapCreateVehicle,
    buildClientError,
    buildTowns,
    buildProvinces,
    mapWalletInvoiceData,
    mapWalletInvoiceDataUrlParams,
    buildWalletInvoiceData,
    buildActiveContracts,
    buildInactiveContracts,
    buildVehicleInspectionBooking,
    buildVehicleInspectionProducts,
    mapSearchVehicleInspectionProducts,
    mapSearchChargingPoint,
    buildChargingPoints,
    buildChargingPointBooking,
    mapParking,
    buildRotation,
    buildEnvironmentalLabel,
    mapRotation,
    mapLanguages,
    mapPrivacy,
    buildVehicleError,
} from './converters'

export const TIMEOUT = 1E6

export async function requestEnvironmentalLabelNumber (numberPlate) {
    const url = Routes.apiGetEnvironmentalLabelNumber({ numberPlate })

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildEnvironmentalLabel(result) }
    }

    return { error: buildClientError(result) }
}

export async function getParking (search) {
    const url = Routes.apiGetParking(mapParking(search))

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildParking(result) }
    }

    return { error: buildError(result) }
}

export async function searchParkings (search) {
    const url = Routes.apiSearchParkings(mapSearchParkings(search))

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildParkings(result) }
    }

    return { error: buildError(result) }
}

export async function searchAirports (search) {
    const url = Routes.apiSearchAirports(search)

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildAirports(result) }
    }

    return { error: buildError(result) }
}

export async function searchPois (search) {
    const url = Routes.apiSearchPoisWithEvents(search)

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildPois(result) }
    }

    return { error: buildError(result) }
}

export async function searchChargingPoints (search) {
    const url = Routes.apiSearchChargingPoints(mapSearchChargingPoint(search))

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildChargingPoints(result) }
    }

    return { error: buildError(result) }
}

export async function searchVehicleInspectionStations (search) {
    const url = Routes.apiSearchVehicleInspectionStation(mapSearchVehicleInspectionStation(search))

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildVehicleInspectionStations(result) }
    }

    return { error: buildError(result) }
}

export async function getAirport (search) {
    const url = Routes.apiFetchAirport(search)

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildAirport(result) }
    }

    return { error: buildError(result) }
}

export async function getBookingAvailability (params) {
    const url = Routes.apiBookingProductsAvailability(mapBookingAvailability(params))

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildBookingAvailability(result) }
    }

    return { error: buildBookingAvailabilityError(result) }
}

export async function getIpCountry () {
    const url = Routes.getIpCountry()

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { ...result }
    }

    return { error: result }
}

export async function getAcceptedCountries () {
    const url = Routes.getAcceptedCountries()

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { ...result }
    }

    return { error: result }
}

export async function getTOS () {
    const url = Routes.getTOS()

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { ...result }
    }

    return { error: result }
}

export async function acceptTOS (version) {
    const url = Routes.acceptTOS()

    const { result, status } = await client.post(url, { version })

    if (status === 'success') {
        return { ...result }
    }

    return { error: result }
}

export async function setPrivacy ({ privacy }) {
    const url = Routes.setPrivacy()

    const { result, status } = await client.put(url, mapPrivacy(privacy))

    if (status === 'success') {
        return { result: buildPrivacy(result) }
    }

    return { error: result }
}

export async function createPrebooking (prebooking) {
    const url = Routes.apiCreatePrebooking()

    const { result, status } = await client.post(url, mapPrebooking(prebooking))

    if (status === 'success') {
        return { result }
    }

    return { error: result }
}

export async function createAccount (account) {
    const url = Routes.apiCreateAccount()

    const { result, status } = await client.post(url, mapAccount(account))

    if (status === 'success') {
        return { result }
    }

    return { error: result }
}

export async function sendContactForm (values) {
    const url = Routes.apiSendContactForm()

    const { result, status } = await client.post(url, mapContactForm(values))

    if (status === 'success') {
        return { result }
    }

    return { error: result }
}

export async function createSetupIntent (params) {
    const url = Routes.apiCreateSetupIntent(mapSetupIntent(params))

    const { result, status } = await client.post(url)

    if (status === 'success') {
        return { result: buildSetupIntent(result) }
    }

    return { error: result }
}

export async function requestBookingInfo (search) {
    const url = Routes.apiBookingPath(mapBooking(search))

    try {
        const { result } = await client.get(url)

        return { result: buildBooking(result) }
    } catch (error) {
        return { error: buildBookingError(error) }
    }
}

export async function requestRotationInfo (search) {
    const url = Routes.apiRotationPath(mapRotation(search))

    try {
        const { result } = await client.get(url)

        return { result: buildRotation(result) }
    } catch (error) {
        return { error: buildClientError(error) }
    }
}

export async function cancelBooking ({ bookingId }) {
    const url = Routes.apiCancelBookingPath({ idBooking: bookingId })
    try {
        const { result } = await client.del(url)

        return { result: buildBooking(result) }
    } catch (error) {
        return { error: buildBookingError(error) }
    }
}

export async function cancelVehicleInspectionBooking ({ bookingId }) {
    const url = Routes.apiCancelVehicleInspectionBookingPath({ bookingId })
    try {
        const { result } = await client.del(url)

        return { result: buildVehicleInspectionBooking(result) }
    } catch (error) {
        return { error: buildBookingError(error) }
    }
}

export async function cancelChargingPointBooking ({ bookingId }) {
    const url = Routes.apiCancelChargingPointBookingPath({ chargingPointBookingId: bookingId })
    try {
        const { result } = await client.del(url)

        return { result: buildChargingPointBooking(result) }
    } catch (error) {
        return { error: buildBookingError(error) }
    }
}

export async function requestLanguages ({ page }) {
    const url = Routes.getAcceptedCountries()

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: mapLanguages(result, country) }
    }

    return { error: buildClientError(result) }
}

export async function requestInvoices ({ page }) {
    const url = Routes.apiRequestInvoices({ page })

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: mapInvoices(result) }
    }

    return { error: buildClientError(result) }
}

export async function requestVehicles () {
    const url = Routes.apiGetVehicles()

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildVehicles({ items: result }) }
    }

    return { error: buildClientError(result) }
}

export async function editVehicle ({ vehicle }) {
    const url = Routes.apiUpdateVehicle({ idVehicle: vehicle.id })

    const { result, status } = await client.put(url, mapEditVehicle(vehicle))

    if (status === 'success') {
        return { result: buildVehicle(result) }
    }

    return { error: buildClientError(result), result: vehicle }
}

export async function requestDeleteVehicle ({ vehicle }) {
    const url = Routes.apiDeleteVehicle({ idVehicle: vehicle.id })

    const { result, status } = await client.del(url)

    if (status === 'success') {
        return { result: { id: vehicle.id } }
    }

    return { error: buildDeleteVehicleError(result), result: { id: vehicle.id } }
}

export async function savePreferences ({ preferences }) {
    const url = Routes.apiSavePreferences()
    const { result, status } = await client.put(url, mapPreferences(preferences))

    if (status === 'success') {
        return { result: buildPreferences(result) }
    }

    return { error: buildError(result) }
}

export async function createVehicle ({ vehicle }) {
    const url = Routes.apiCreateVehicle()

    const { result, status } = await client.post(url, mapCreateVehicle(vehicle))
    if (status === 'success') {
        return { result: buildVehicle(result) }
    }

    return { error: buildVehicleError(result), result: vehicle }
}

export async function requestInvoiceXlsx ({ invoiceUid }) {
    const url = Routes.apiRequestInvoiceXlsx({ invoiceUid })
    const { result, status } = await client.get(url)

    if (status === 'success') {
        return {}
    }
    return { error: buildClientError(result) }
}

export async function getProvinces () {
    const url = Routes.apiRequestProvinces()
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildProvinces(result) }
    }

    return { error: buildClientError(result) }
}

export async function getTownsByProvince (id) {
    const url = Routes.apiRequestTownsByProvince({ id })
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildTowns(result) }
    }

    return { error: buildClientError(result) }
}

export async function getWalletInvoiceData ({ walletId }) {
    const url = Routes.apiRequestWalletInvoiceData(mapWalletInvoiceDataUrlParams({ walletId }))
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildWalletInvoiceData(result) }
    }

    return { error: buildClientError(result) }
}

export async function getPrivacy () {
    const url = Routes.apiGetPrivacy()
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildPrivacy(result) }
    }

    return { error: buildClientError(result) }
}

export async function updateWalletInvoiceData ({ walletInvoiceData, walletId }) {
    const url = Routes.apiUpdateWalletInvoiceData(mapWalletInvoiceDataUrlParams({ walletId }))
    const { result, status } = await client.put(url, mapWalletInvoiceData(walletInvoiceData))
    if (status === 'success') {
        return { result: buildWalletInvoiceData(result) }
    }

    return { error: buildClientError(result) }
}

export async function requestWalletData ({ walletId }) {
    const url = Routes.apiRequestWalletData(mapFetchWalletParams(walletId))
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildWallet(result) }
    }

    return { error: buildClientError(result) }
}

export async function getActiveContracts () {
    const url = Routes.apiGetActiveContracts()
    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildActiveContracts(result) }
    }

    return { error: buildClientError(result) }
}

export async function getInactiveContracts ({ page, contractTypes }) {
    const url = Routes.apiGetInactiveContracts({ page, 'contract_types': contractTypes.join(',') })
    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildInactiveContracts(result) }
    }

    return { error: buildClientError(result) }
}

export async function sendSerTicket (id) {
    const url = Routes.serTicketSend({ idSERTicket: id })
    const { status } = await client.get(url)
    if (status === 'success') {
        return { error: false }
    }

    return { error: true }
}

export async function requestVehicleInspectionBooking ({ bookingId }) {
    const url = Routes.apiRequestVehicleInspectionBooking({ bookingId })
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildVehicleInspectionBooking(result) }
    }

    return { error: buildClientError(result) }
}

export async function requestChargingPointBooking ({ bookingId }) {
    const url = Routes.apiRequestChargingPointBooking({ chargingPointBookingId: bookingId })
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildChargingPointBooking(result) }
    }

    return { error: buildClientError(result) }
}

export async function requestVehicleInspectionProducts ({ stationId, vehicleFilter }) {
    const url = Routes.apiRequestVehicleInspectionProducts(mapSearchVehicleInspectionProducts({ stationId, vehicleFilter }))
    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildVehicleInspectionProducts(result) }
    }

    return { error: buildClientError(result) }
}

export {
    login,
    socialLogin,
    getUser,
    getUserExists,
    getViatParkingsByCountry,
    getViatHighwayDiscounts,
    uploadVehicleCsvFile,
    uploadTrailerCsvFile,
    uploadWalletBeneficiaryCsvFile,
    createWalletBeneficiary,
    deleteWalletBeneficiary,
    getOccupation,
    getOccupationHours,
    getViatOrderData,
    getCreditCards,
    getUserWallets,
    getUserPricePerWallet,
    getCreditCardInfo,
    getViatProducts,
    payNewUserViatOrder,
    payExistingUserViatOrder,
    getViatOrders,
    createViatOrder,
    updateViatOrder,
    confirmPaymentIntent,
    cancelViatOrder,
    getProvincesForShop,
    getMobes,
    getTolls,
    getPagedWalletBeneficiaries,
    bindVehicleToMobe,
    unbindVehicleToMobe,
    bindWalletToMobe,
    unassignMobe,
    getEnabledMobes,
    getGlobalConfig,
    setWorkshopBookingProduct,
    assignWorkshopToBookingWorkshop,
    getSuitableBookingWorkshops,
    setWorkshopBookingVehicle,
    getWorkshopBooking,
    cancelWorkshopBooking,
    requestBrands,
    getTwoFaActiveMethod,
    activeTwoFaActiveEmailMethod,
    deactivateTwoFaActiveEmailMethod,
    getFineTowns,
    getVehicles,
    updateBookingVehicle,
    getBookingAvailabilityUpdateDates,
    updateBookingDates,
    getWorkshopBookingOccupation,
    createCreditCard,
    confirmWorkshopBooking,
    setWorkshopBookingDate,
    updateUserData,
    updateVehicleKm,
    getPagedVehicles,
    getWalletPreferences,
    getWallet,
    associateCreditCard,
    getAutoDepositTiers,
    updateWalletPreferences,
    getAdvancedVehicleAdmin,
    createDeposit,
    createTrailer,
    getPagedTrailers,
    deleteTrailer,
    getDepositTiers,
    deleteCreditCard,
    getWalletMovements,
    updateCorporatePlatesWhitelistEnabled,
    getPagedCorporateWhitelistedNumberPlates,
    sendMovementsToEmail,
    deleteCorporateWhitelistedNumberPlate,
    createCorporateWhitelistedNumberPlate,
    uploadNumberPlateWhitelistCsvFile,
    getWalletBeneficiariesAll,
    deleteAllCorporateWhitelistedNumberPlates,
}
