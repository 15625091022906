import Routes from '../routes/routes'
import { buildDelApi } from './helpers'
import { buildOrder as buildResult } from './converters/viatOrder'

function mapParams ({ id }) {
    return { id }
}

export default buildDelApi({
    route: Routes.apiDeletePagateliaOrderData,
    mapParams,
    buildResult,
})
