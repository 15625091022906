import Routes from '../routes/routes'
import { buildDelApi } from './helpers'

export default buildDelApi({
    route: Routes.apiDeleteCorporateWhitelistedNumberPlate,
    buildResult: () => true,
    mapParams: ({walletId, id}) => ({
        walletId,
        walletPlateId: id,
    }),
    mapPayload: () => {},
})
