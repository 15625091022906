export function buildDeposits (deposit) {
    return {
        id: deposit.id,
        brand: deposit.brand,
        brandSlug: deposit.brandSlug,
        expMonth: deposit.exp_month,
        expYear: deposit.exp_year,
        expDate: deposit.expDate,
        lastNumbers: deposit.last_numbers,
    }
}
export function buildDepositCoupon (coupon) {
    return {
        amount: coupon.amount,
        businessName: coupon.business_name,
        expirationDate: coupon.expiration_date,
        id: coupon.id,
        idCouponOrder: coupon.id_coupon_order,
        qty: coupon.qty,
        redeem_code: coupon.redeemCode,
    }
}
