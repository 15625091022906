import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { buildMobe } from './converters/mobe'

function buildResult (data) {
    return data.map(buildMobe)
}

export default buildGetApi({
    route: Routes.apiGetEnabledMobes,
    buildResult,
})
