import moment from 'moment-timezone'
import type Moment from 'moment'

import {
    TIMESTAMP_FORMAT,
    DATE_FORMAT,
    DATETIME_FORMAT,
    LOCALE_TIME_FORMAT,
} from '../constants'

type DateParts = {
    year: number,
    month: number,
    date: ?number,
}

type TimeParts = {
    minutes: ?number,
    hours: ?number,
    seconds: ?number,
}

function strictParseMoment (value, format = ''): Moment {
    return moment(value, format, true)
}

export function parseMoment (value, format = ''): Moment {
    const m = strictParseMoment(value, format)

    if (!m.isValid()) {
        throw new Error(`Invalid date \`${String(value)}\` (${format})`)
    }

    return m
}

export function maybeParseMoment (value, format = '', defaultValue) {
    const m = strictParseMoment(value, format)

    return m.isValid() ? m : defaultValue
}

export function parseTimestamp (value) {
    return parseMoment(value, TIMESTAMP_FORMAT)
}

export function maybeParseTimestamp (value, defaultValue) {
    const m = strictParseMoment(value, TIMESTAMP_FORMAT)

    return m.isValid() ? m : defaultValue
}

export function formatDay (day: ?number | ?Date | ?Moment, format): string {
    return day
        ? parseMoment(day).format(format)
        : ''
}

export function toDate (day: ?Moment): Date {
    return day
        ? parseMoment(day).toDate()
        : new Date()
}

export function toTimestamp (day: Moment): number {
    return parseMoment(day).unix()
}

export function formatDate (day, format = DATE_FORMAT) {
    return moment(day).format(format)
}

export function formatDateTime (day) {
    return moment(day).format(DATETIME_FORMAT)
}

export function formatTimestamp (day) {
    return moment(day).format(TIMESTAMP_FORMAT)
}

export function formatISO (day) {
    return moment(day).toISOString()
}

export function formatMinuteDuration (minutes: number): string {
    const duration = moment.duration(minutes, 'minutes')
    const durationComponents = [
        { value: duration.years(), unit: 'y' },
        { value: duration.months(), unit: 'M' },
        { value: duration.days(), unit: 'd' },
        { value: duration.hours(), unit: 'h', isoUnit: 'h' },
        { value: duration.minutes(), unit: 'm', isoUnit: 'min' },
        { value: duration.seconds(), unit: 's', isoUnit: 's' },
    ]

    return durationComponents
        .filter(({ value }) => value !== 0)
        .slice(0, 3)
        .map(({ unit, value, isoUnit }) => isoUnit
            ? `${value}${isoUnit}`
            : moment.duration(value, unit).humanize())
        .join(', ')
}

export function makeHourTimeSpan (hours: number): number {
    return moment.duration(hours, 'hours').asMilliseconds()
}

export function makeMinuteTimeSpan (minutes: number): number {
    return moment.duration(minutes, 'minutes').asMilliseconds()
}

export function mergeDate (date: Date, partialDate: DateParts | TimeParts): Date {
    return toDate(parseMoment(date).set(partialDate))
}

export function hasMeridiem () {
    return moment().format(LOCALE_TIME_FORMAT).toLowerCase().indexOf('m') > -1
}

export function toParts (day) {
    const m = parseMoment(day)
    return [
        m.year(),
        m.month(),
        m.date(),
        m.hours(),
        m.minutes(),
        m.seconds(),
    ]
}

export function toMillis (seconds) {
    return seconds * 1000
}

export function getBrowserTimezone () {
    return moment.tz.guess()
}
