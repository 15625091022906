import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { mapPaginator } from './converters/paginator'
import { buildVehicle } from './converters/vehicle'

function buildVehicles (data) {
    return mapPaginator(data, buildVehicle)
}

function mapParams ({ page, numberPlate = null }) {
    return { page, number_plate: numberPlate }
}

export default buildGetApi({
    route: Routes.apiGetPagedVehicles,
    mapParams,
    buildResult: buildVehicles,
})
