import Routes from '../routes/routes'
import { buildWorkshops } from './converters/workshopBooking'
import { buildGetApi } from './helpers'

function mapParams ({ bookingId, latitude, longitude, radius = 5 }) {
    return {
        bookingId,
        latitude,
        longitude,
        radius,
    }
}

export default buildGetApi({
    route: Routes.apiSuitableBookingWorkshops,
    buildResult: buildWorkshops,
    mapParams,
})
