import Routes from '../routes/routes'
import { buildPatchApi } from './helpers'
import { buildBooking } from './converters'
import { parseTimestamp, formatDay } from 'commons/js/util/dateConverter'
import { DATETIME_FORMAT_WITH_SECONDS_WITH_SPACE } from 'commons/js/constants'

function mapParams ({ bookingId }) {
    return {
        idBooking: bookingId,
    }
}

function mapPayload ({ walletId, startDate, endDate }) {
    return {
        id_wallet: walletId,
        start_date: formatDay(
            parseTimestamp(startDate),
            DATETIME_FORMAT_WITH_SECONDS_WITH_SPACE
        ),
        end_date: formatDay(
            parseTimestamp(endDate),
            DATETIME_FORMAT_WITH_SECONDS_WITH_SPACE,
        ),
    }
}

export default buildPatchApi({
    route: Routes.updateBookingDates,
    buildResult: buildBooking,
    mapPayload,
    mapParams,
})
