import { getConstant } from './constants'

export const BRA = 'bra'
export const COL = 'col'
export const CRI = 'cri'
export const ESP = 'esp'
export const USA = 'usa'
export const ECU = 'ecu'

const currentCountry = getConstant('COUNTRY') || ESP

export default currentCountry
