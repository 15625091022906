import Routes from '../routes/routes'
import { buildPutApi } from './helpers'

export default buildPutApi({
    route: Routes.apiVehicleUpdateKm,
    buildResult: () => true,
    mapPayload: ({ id, km }) => {
        return ({
            id_vehicle: id,
            current_km: km,
        })
    },
    mapParams: ({ id: idVehicle }) => ({ idVehicle }),
})
