/* istanbul ignore file */

import {
    UNAUTHORIZED_ERROR,
    FORBIDDEN_ERROR,
    NOT_FOUND_ERROR,
    UNPROCESSABLE_ENTITY_ERROR,
    SERVER_ERROR,
    INVALID_FORMAT_ERROR,
    TIMEOUT_ERROR,
    UNKNOWN_ERROR,
} from './constants'
import { get, post, put, del, patch, postMultipart } from '../client/fetch'
import * as Errors from '../client/errors'

export function isApiError (error) {
    return error instanceof Errors.UnprocessableEntityError && Number.isInteger(error.response.status)
}

export function isValidationError (error) {
    return error instanceof Errors.UnprocessableEntityError && Array.isArray(error.response)
}

export function buildApiError (error) {
    return {
        ...buildClientError(error),
        status: error.response.status,
        data: error.response.data,
    }
}

export function buildValidationError (error) {
    const [ firstError ] = error.response

    return {
        field: firstError.field,
        message: firstError.message,
    }
}

export function buildClientError (error) {
    if (error instanceof Errors.UnauthorizedError) {
        return { name: UNAUTHORIZED_ERROR }
    }
    if (error instanceof Errors.ForbiddenError) {
        return { name: FORBIDDEN_ERROR }
    }
    if (error instanceof Errors.NotFoundError) {
        return { name: NOT_FOUND_ERROR }
    }
    if (error instanceof Errors.UnprocessableEntityError) {
        return { name: UNPROCESSABLE_ENTITY_ERROR }
    }
    if (error instanceof Errors.ServerError) {
        return { name: SERVER_ERROR }
    }
    if (error instanceof Errors.InvalidFormatError) {
        return { name: INVALID_FORMAT_ERROR }
    }
    if (error instanceof Errors.TimeoutError) {
        return { name: TIMEOUT_ERROR }
    }
    return { name: UNKNOWN_ERROR }
}

export function buildGenericError (error) {
    if (isValidationError(error)) {
        return buildValidationError(error)
    }

    if (isApiError(error)) {
        return buildApiError(error)
    }

    return buildClientError(error)
}

const identity = (value) => value

export function buildApi ({
    route,
    request,
    mapParams = identity,
    mapPayload = identity,
    buildResult = identity,
    buildError = buildGenericError,
}) {
    return async (params, headers) => {
        const url = route(mapParams(params))

        try {
            const { result } = await request({ url, headers, data: mapPayload(params) })
            return { result: buildResult(result) }
        } catch (error) {
            return { error: buildError(error) }
        }
    }
}

export const buildGetApi = (configuration) => buildApi({ ...configuration, request: get })
export const buildPostApi = (configuration) => buildApi({ ...configuration, request: post })
export const buildPatchApi = (configuration) => buildApi({ ...configuration, request: patch })
export const buildPutApi = (configuration) => buildApi({ ...configuration, request: put })
export const buildDelApi = (configuration) => buildApi({ ...configuration, request: del })

export const buildMultipartPostApi = (configuration) => buildApi({ ...configuration, request: postMultipart })
