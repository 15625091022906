import { buildVehicle } from './vehicle'

export function buildFine (data) {
    return {
        id: data.id,
        date: data.date || null,
        dueDate: data.due_date || null,
        vehicle: data.vehicle ? buildVehicle(data.vehicle) : null,
        town: data.SERTown ? {
            name: data.SERTown.name,
            timeZone: data.SERTown.timeZone,
        } : null,
        article: data.article,
        description: data.description,
        fineType: data.fine_type,
        parking: data.parking ? {
            id: data.parking.id,
            name: data.parking.name,
            timeZone: data.parking.time_zone_name,
        } : null,
        bulletinNumber: data.bulletin_number,
    }
}
