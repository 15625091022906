import Routes from '../routes/routes'
import { buildPutApi } from './helpers'
import { buildMobe } from './converters/mobe'

function mapParams ({ idMobe }) {
    return {
        idMobe,
    }
}

export function mapPayload ({ idWallet }) {
    return ({
        walletId: idWallet,
    })
}

export default buildPutApi({
    route: Routes.apiBindWalletToMobe,
    buildResult: buildMobe,
    mapParams,
    mapPayload,
})
