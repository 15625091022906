import Routes from '../routes/routes'
import { buildTiers } from './converters/wallet'

import { buildGetApi } from './helpers'

function mapParams ({ countryId }) {
    return { countryId }
}

export default buildGetApi({
    route: Routes.apiGetAutoDepositTiers,
    buildResult: buildTiers,
    mapParams,
})
