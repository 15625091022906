import Routes from '../routes/routes'
import { buildWorkshopBooking } from './converters/workshopBooking'
import { buildGetApi } from './helpers'

function mapParams ({ workshopBookingId: bookingId }) {
    return { bookingId }
}

export default buildGetApi({
    route: Routes.apiWorkshopBookingPath,
    buildResult: buildWorkshopBooking,
    mapParams,
})
