import Routes from '../routes/routes'
import { buildMultipartPostApi } from './helpers'

function buildResult () {
    return true
}

function mapPayload ({ files, walletId, goAndPark: goPark }) {
    return {
        files,
        walletId,
        goPark,
    }
}

export default buildMultipartPostApi({
    route: Routes.apiUploadVehicleCsvFile,
    buildResult,
    mapParams: () => {},
    mapPayload,
})
