import Routes from '../routes/routes'
import { mapPaginator } from './converters/paginator'
import { buildWalletMovement } from './converters/wallet'

import { buildGetApi } from './helpers'

function mapParams ({ id, page, numberPlate = null, startDate = null, endDate = null, beneficiaryId = null }) {
    return {
        walletId: id,
        page,
        numberPlate,
        start_date: startDate,
        end_date: endDate,
        beneficiaryId,
    }
}

export default buildGetApi({
    route: Routes.apiGetWalletMovements,
    buildResult: (data) => mapPaginator(data, buildWalletMovement),
    mapParams,
})
