import Routes from '../routes/routes'
import { buildPatchApi } from './helpers'
import { buildVehicle } from './converters/vehicle'

function mapParams ({ bookingId: idBooking }) {
    return {
        idBooking,
    }
}

function mapPayload ({ vehicleId }) {
    return {
        id_vehicle: vehicleId,
    }
}

export default buildPatchApi({
    route: Routes.apiBookingUpdateVehicle,
    buildResult: ({ vehicle }) => buildVehicle(vehicle),
    mapPayload,
    mapParams,
})
