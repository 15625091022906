import Routes from '../routes/routes'
import { buildDelApi } from './helpers'

function buildResult () {
    return true
}

export default buildDelApi({
    route: Routes.apiActiveTwoFaActiveEmailMethod,
    buildResult,
})
