import Routes from '../routes/routes'
import { buildWalletBeneficiary } from './converters/walletBeneficiary'

import { buildGetApi } from './helpers'

function mapParams ({ walletId }) {
    return { walletId }
}

function buildBeneficiaryList (result) {
    return result.map(buildWalletBeneficiary)
}

export default buildGetApi({
    route: Routes.apiGetWalletBeneficiariesAll,
    buildResult: buildBeneficiaryList,
    mapParams,
})
