import language from 'commons/js/locale'
import country from 'commons/js/country'
import messages from './messages'
import { getConstant } from 'commons/js/constants'

const csrfHeaders = {'X-csrf-protection': getConstant('CSRF_PROTECTION_HEADER')}

const defaultHeaders = () => ({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': language,
    'X-Country': country,
})

const multipartHeaders = () => ({
    'Accept': 'application/json',
    'Accept-Language': language,
    'X-Country': country,
})

function addData (form, name, item) {
    // eslint-disable-next-line no-undef
    if (item instanceof File) {
        form.append(name, item, item.name)
    } else {
        form.append(name, item)
    }
}

function serializeFormData (data) {
    return Object.keys(data).reduce((form, name) => {
        if (Array.isArray(data[name])) {
            data[name].forEach((item, index) => {
                addData(form, `${name}_${index}`, item)
            })
        } else {
            addData(form, name, data[name])
        }
        return form
    }, new window.FormData())
}

async function wrapResponse (response) {
    const responseText = await response.text()

    if (response.status === 401) {
        return {status: 'error', result: {message: messages.sessionexpired}}
    }

    if (response.status >= 500) {
        return {status: 'error', result: {message: messages.internalerror}}
    }

    let result

    try {
        if (responseText.length === 0) {
            result = {}
        } else {
            result = JSON.parse(responseText)
        }
    } catch (parseError) {
        return {status: 'error', result: {message: messages.invalidformat}}
    }

    if (response.ok) {
        return {status: 'success', result}
    }

    return {status: 'error', result}
}

export async function get (url, params, options = {}) {
    const response = await window.fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: defaultHeaders(),
        ...options,
    })

    return wrapResponse(response)
}

export async function post (url, data, options = {}) {
    const response = await window.fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {...defaultHeaders(), ...csrfHeaders},
        body: JSON.stringify(data),
        ...options,
    })

    return wrapResponse(response)
}

export async function postMultipart (url, data, options = {}) {
    const response = await window.fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {...multipartHeaders(), ...csrfHeaders},
        body: serializeFormData(data),
        ...options,
    })

    return wrapResponse(response)
}

export async function put (url, data, options = {}) {
    const response = await window.fetch(url, {
        method: 'PUT',
        credentials: 'include',
        headers: {...defaultHeaders(), ...csrfHeaders},
        body: JSON.stringify(data),
        ...options,
    })

    return wrapResponse(response)
}

export async function del (url, data, options = {}) {
    const response = await window.fetch(url, {
        method: 'DELETE',
        credentials: 'include',
        headers: {...defaultHeaders(), ...csrfHeaders},
        body: JSON.stringify(data),
        ...options,
    })

    return wrapResponse(response)
}
