const defaultMapper = (x) => x

export function mapPaginator ({
        pageCount: pages,
        currentPage: page,
        items = [],
    },
    mapper = defaultMapper
) {
    return {
        pages,
        page,
        items: items.map(mapper),
    }
}
