import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { buildCard } from './converters/creditCard'

export function buildCards (result) {
    return result.map(buildCard)
}

export default buildGetApi({
    route: Routes.apiGetCreditCards,
    buildResult: buildCards,
})
