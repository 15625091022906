import {
    STATUS_PAGATELIA_ORDER_REFUNDED,
    STATUS_PAGATELIA_ORDER_CANCELED,
} from 'commons/js/constants'

export function buildOrder ({ id, pagateliaOrderLines, is_cancellable: cancellable, status, created_at: date, total_qty: totalQty }) {
    return {
        id,
        description: pagateliaOrderLines && pagateliaOrderLines[0].name,
        date,
        totalQty,
        cancellable,
        status,
        cancelled: status === STATUS_PAGATELIA_ORDER_CANCELED || status === STATUS_PAGATELIA_ORDER_REFUNDED,
    }
}
