import Routes from '../routes/routes'
import { buildPostApi } from './helpers'
import { buildResult } from './converters/viatOrderData'
import { buildClientError } from 'commons/js/api/converters'
import { UnprocessableEntityError } from '../client/errors'

function mapParams () {
    return {}
}

function mapPayload ({ order }) {
    return {
        orderId: order.id,
        userEmail: order.shippingData.email,
        setup_intent_id: order.creditCard.setupIntentId,
        promo_code: order.promoCode ? order.promoCode.code : undefined,
    }
}

function buildError (error) {
    if (error instanceof UnprocessableEntityError) {
        const data = { ...error.response.data }
        return {
            ...buildClientError(error),
            status: error.response.status,
            data: {
                promoCode: data.promotional_code,
                clientSecret: data.client_secret,
            },
        }
    }

    return buildClientError(error)
}

export default buildPostApi({
    route: Routes.apiPayNewUserViatOrder,
    mapParams,
    mapPayload,
    buildResult,
    buildError,
})
