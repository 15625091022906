import {
    OPENING_MODE_VIAT_EXIT,
    OPENING_MODE_VIAT_ENTRY_EXIT,
    PARKING_PROVIDER_AENA,
    SERVICE_GROUP_TYPE_MANDATORY,
    AVAILABLE,
    DATETIME_FORMAT_WITH_SECONDS,
    STATUS_UNAVAILABLE_RELEASE,
} from 'commons/js/constants'

import { parseTimestamp, formatDay } from 'commons/js/util/dateConverter'
import { buildBookingService } from './booking-service'
import UrlTo from 'commons/js/util/UrlTo'

const getIsOfficial = parking => {
    return parking.provider === PARKING_PROVIDER_AENA
}

const getTypeString = parking => {
    if (
        parking.opening_mode === OPENING_MODE_VIAT_EXIT ||
        parking.opening_mode === OPENING_MODE_VIAT_ENTRY_EXIT
    ) {
        return 'viat'
    }

    if (parking.is_airport_parking) {
        return 'airport'
    }

    if (parking.shared) {
        return 'shared'
    }

    return 'default'
}

function getMedia (parking, size) {
    const { medias } = parking
    return medias && medias.length ? medias[0]['url_' + size + '_size'] : null
}

function getIsActive ({ bookingAvailability }) {
    if (!bookingAvailability) {
        return false
    }

    return bookingAvailability.availabilityStatus === AVAILABLE
}

function isUnavailableDueToRelease ({ bookingAvailability }) {
    if (!bookingAvailability) {
        return false
    }

    return STATUS_UNAVAILABLE_RELEASE.includes(bookingAvailability.availabilityStatus)
}

function getBookingServices (parking) {
    if (!parking.groupedParkingBookingServices) {
        return []
    }
    return parking.groupedParkingBookingServices.reduce(
        (services, group) => [
            ...services,
            ...group.services.map(buildBookingService),
        ],
        []
    )
}

function getBookingRestriction ({ bookingRestriction }) {
    if (!bookingRestriction) {
        return null
    }

    return {
        maxStayMinutes: bookingRestriction.max_stay_minutes,
        minStayMinutes: bookingRestriction.min_stay_minutes,
        advanceMinutes: bookingRestriction.advance_minutes,
    }
}

function getGroupedParkingBookingServices (parking) {
    if (!parking.groupedParkingBookingServices) {
        return []
    }
    return parking.groupedParkingBookingServices
        .map(group => {
            if (group.type !== SERVICE_GROUP_TYPE_MANDATORY) {
                return group
            }

            if (!group.services.length) {
                return group
            }

            if (group.services.some(service => service.selected_by_default)) {
                return group
            }

            return {
                ...group,
                services: [
                    { ...group.services[0], selected_by_default: true },
                    ...group.services.slice(1),
                ],
            }
        })
        .map(({ type, name, services }) => ({
            type,
            name,
            services: services.map(buildBookingService),
        }))
}

function getParkingUrl ({ id: idParking, slug, province }) {
    if (!province) {
        return null
    }
    return UrlTo.get('parking/show', {
        idParking,
        slug,
        province: province.slug,
    })
}

export function buildParking (parking) {
    return {
        ...parking,
        openingModeTitle: parking.opening_mode_title,
        openingModeDescription: parking.opening_mode_description,
        openingMethod: parking.opening_mode,
        isActive: getIsActive(parking),
        isMowizTruck: parking.is_mowiztruck,
        isUnavailableDueToRelease: isUnavailableDueToRelease(parking),
        typeString: getTypeString(parking),
        isAirport: parking.is_airport_parking,
        isOfficial: getIsOfficial(parking),
        url: getParkingUrl(parking),
        media: {
            small: getMedia(parking, 'small'),
            regular: getMedia(parking, 'regular'),
            medium: getMedia(parking, 'medium'),
        },
        bookingServices: getBookingServices(parking),
        bookingRestriction: getBookingRestriction(parking),
        groupedParkingBookingServices: getGroupedParkingBookingServices(parking),
        maxHeight: parking.max_height,
        maxLength: parking.max_length,
        onlyTrucksAllowed: parking.only_trucks_allowed,
    }
}

export function buildParkings (parkings) {
    return parkings.map(parking => buildParking(parking))
}

export function buildAirport (airport) {
    return {
        ...airport,
    }
}

export function buildAirports (airports) {
    return airports.map(buildAirport)
}

export function buildPoi (poi) {
    return {
        ...poi,
    }
}

export function buildPois (pois) {
    return pois.map(buildPoi)
}

export function mapSearchParkings ({ latitude, longitude, start, end, radius, bounds, latN, latS, lonE, lonW, zoom }) {
    return {
        latitude,
        longitude,
        start_date: formatDay(
            parseTimestamp(start),
            DATETIME_FORMAT_WITH_SECONDS
        ),
        radius,
        latN,
        latS,
        lonE,
        lonW,
        zoom,
        end_date: formatDay(parseTimestamp(end), DATETIME_FORMAT_WITH_SECONDS),
    }
}

export function mapParking ({ parkingId }) {
    return {
        idParking: parkingId,
    }
}
