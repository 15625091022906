import Routes from '../routes/routes'
import { buildWallets } from './converters'
import { buildGetApi } from './helpers'

function buildResult (user) {
    return buildWallets(user)
}

export default buildGetApi({
    route: Routes.apiGetUserWallets,
    buildResult,
})
