export function buildWalletInvoiceData (walletInvoiceData) {
    return {
        ...walletInvoiceData,
        province: walletInvoiceData.province_name,
        walletId: walletInvoiceData.id_wallet,
        countryIso3: walletInvoiceData.country_iso3,
        countryId: walletInvoiceData.id_country,
        countryName: walletInvoiceData.country_name,
        provinceId: walletInvoiceData.id_province ? `${walletInvoiceData.id_province}` : '',
    }
}

export function mapWalletInvoiceData (walletInvoiceData) {
    return {
        ...walletInvoiceData,
        id_province: walletInvoiceData.provinceId,
    }
}

export function mapWalletInvoiceDataUrlParams ({walletId}) {
    return {
        idWallet: walletId,
    }
}
