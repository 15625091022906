import Routes from '../routes/routes'
import { buildGetApi } from './helpers'

function mapParams (email) {
    return {
        email,
    }
}

export default buildGetApi({
    route: Routes.apiCheckUserExists,
    mapParams,
})
