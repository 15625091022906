import Routes from '../routes/routes'
import { buildUser } from './converters/user'
import { buildPostApi } from './helpers'

function mapPayload (login) {
    return {
        access_token: login.accessToken,
        server_code: login.code,
        social_network_name: login.network,
        social_email: login.email,
        country_code: login.country,
        application: login.application,
    }
}

function buildResult (user) {
    return buildUser(user)
}

export default buildPostApi({
    route: Routes.socialLogin,
    mapPayload,
    buildResult,
})
