import Routes from '../routes/routes'
import { buildDelApi } from './helpers'
import { buildTrailer } from './converters/trailer'

export default buildDelApi({
    route: Routes.apiDeleteTrailer,
    buildResult: buildTrailer,
    mapParams: ({trailer}) => ({ id: trailer.id }),
    mapPayload: () => {},
})
