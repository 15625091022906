import Routes from '../routes/routes'
import { buildGetApi } from './helpers'

function buildResult (data) {
    return {
        vehicle: data.vehicle_admin_enabled,
        trailer: data.trailer_admin_enabled,
    }
}

export default buildGetApi({
    route: Routes.apiCheckAdvancedVehiclesAdmin,
    buildResult,
})
