import Routes from '../routes/routes'
import { buildVechicleVersions } from './converters/workshopBooking'
import { buildPostApi } from './helpers'
import { UnprocessableEntityError } from '../client/errors'
import { buildClientError } from 'commons/js/api/converters'

function mapParams () {
    return { }
}

function mapPayload ({ vehicleId, version }) {
    if (version) {
        return {
            id_vehicle: vehicleId,
            externalIds: [{
                id_provider: version.providerId,
                id_external: version.externalId,
            }],
        }
    }
    return ({ id_vehicle: vehicleId })
}

function buildError (error) {
    if (error instanceof UnprocessableEntityError) {
        const data = { ...error.response.data }
        return {
            ...buildClientError(error),
            status: error.response.status,
            versions: buildVechicleVersions(data.vehicleVersions),
        }
    }

    return buildClientError(error)
}

export default buildPostApi({
    route: Routes.apiCreateWorkshopBooking,
    mapParams,
    mapPayload,
    buildError,
})
