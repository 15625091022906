import Routes from '../routes/routes'
import { buildDelApi } from './helpers'
import { buildWorkshopBooking } from './converters/workshopBooking'

function mapParams ({ bookingId }) {
    return { bookingId }
}

export default buildDelApi({
    route: Routes.apiCancelWorkshopBookingPath,
    buildResult: buildWorkshopBooking,
    mapParams,
})
