import Routes from '../routes/routes'
import { buildMultipartPostApi } from './helpers'

function buildResult () {
    return true
}

export default buildMultipartPostApi({
    route: Routes.apiUploadNumberPlateWhitelistCsvFile,
    buildResult,
    mapParams: ({ walletId }) => ({ walletId }),
    mapPayload: ({ files }) => ({ files }),
})
