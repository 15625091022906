import { parseMoment } from 'commons/js/util/dateConverter'

const isExpired = (expYear, expMonth) => {
    const date = parseMoment([expYear, expMonth - 1, 1, 0, 0, 0])
    return parseMoment().subtract(1, 'month').diff(date) > 0
}

export function buildCard (card) {
    return ({
        id: card.id,
        lastNumbers: card.last_numbers,
        brand: card.brand,
        expirationMonth: card.exp_month,
        expirationYear: card.exp_year,
        token: card.token,
        setupIntentId: card.setup_intent_id,
        expired: isExpired(card.exp_year, card.exp_month),
    })
}
