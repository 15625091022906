export function buildRefuelTicket (refuelTicket) {
    return {
        id: refuelTicket.id,
        petrolStation: {
            id: refuelTicket.petrol_station.id,
            name: refuelTicket.petrol_station.name,
        },
        product: {
            id: refuelTicket.product.id,
            name: refuelTicket.product.name,
        },
        createdAt: refuelTicket.created_at,
        expirationDate: refuelTicket.expiration_date,
        status: refuelTicket.status,
        refuelDate: refuelTicket.refuel_date,
        usedLitres: refuelTicket.used_litres,
        usedQty: refuelTicket.used_qty,
        taxPercentage: refuelTicket.tax_percentage,
        taxName: refuelTicket.tax_name,
        amount: refuelTicket.amount,
    }
}
