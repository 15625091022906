import Routes from '../routes/routes'
import { buildPostApi } from './helpers'

function mapParams ({ id: bookingId }) {
    return {
        bookingId,
    }
}

export default buildPostApi({
    route: Routes.apiConfirmWorkshopBooking,
    mapParams,
})
