import { buildVehicle } from './vehicle'
import { buildParking } from './parking'

export function buildSeasonTicket (data) {
    return {
        id: data.id,
        endDate: data.end_date || null,
        startDate: data.start_date,
        parking: buildParking(data.parking),
        vehicle: data.vehicle ? buildVehicle(data.vehicle) : null,
        seasonTicket: {
            name: data.season_ticket.name,
        },
        canceled: data.canceled,
    }
}
