import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { buildToll } from './converters/toll'
import { mapPaginator } from './converters/paginator'

function mapParams ({ page }) {
    return { page }
}

function buildResult (data) {
    return mapPaginator(data, buildToll)
}

export default buildGetApi({
    route: Routes.apiGetTolls,
    buildResult,
    mapParams,
})
