function buildCouponOrderLines (line) {
    return {
        numCoupons: line.num_coupons,
        qty: line.qty,
        totalQty: {
            currency: line.qty.currency,
            amount: line.qty.amount * line.num_coupons,

        },
    }
}
export function buildCouponOrder (coupon) {
    return {
        id: coupon.id,
        date: coupon.date,
        couponExpirationDate: coupon.coupons_expiration_date,
        lines: coupon.lines.map(line => buildCouponOrderLines(line)),
        qty: coupon.qty,
    }
}

export function buildCouponOrderFee (coupon) {
    return {
        id: coupon.id_coupon_order,
        paymentDueDate: coupon.payment_due_date,
    }
}
