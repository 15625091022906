import Routes from '../routes/routes'
import { buildGetApi } from './helpers'

function mapParams ({ countryId }) {
    return {
        idCountry: countryId,
    }
}

const NULL_MEDIA = {
    small: null,
    regular: null,
    medium: null,
}

function buildMedia ([ firstMedia ]) {
    if (firstMedia) {
        return {
            small: firstMedia.url_small_size,
            regular: firstMedia.url_regular_size,
            medium: firstMedia.url_medium_size,
        }
    }

    return NULL_MEDIA
}

function buildParking (parking) {
    return {
        id: parking.id,
        name: parking.name,
        address: parking.address,
        slug: parking.slug,
        media: buildMedia(parking.medias),
        province: {
            name: parking.province.name,
            slug: parking.province.slug,
        },
    }
}

function buildResult (parkings) {
    return parkings.map(buildParking)
}

export default buildGetApi({
    route: Routes.apiGetViatParkingsByCountry,
    buildResult,
    mapParams,
})
