import Routes from '../routes/routes'
import { buildPutApi } from './helpers'
import { buildMobe } from './converters/mobe'

function mapParams ({ id }) {
    return { idMobe: id }
}

function buildResult (data) {
    return buildMobe(data)
}

export default buildPutApi({
    route: Routes.apiUnassignMobe,
    buildResult,
    mapParams,
})
