import Routes from '../routes/routes'
import { buildPostApi } from './helpers'

export function mapPayload ({ paymentIntent }) {
    return {
        payment_intent: paymentIntent,
    }
}

export function mapParams ({ idWallet }) {
    return {
        idWallet,
    }
}

export function buildResult ({ id, qty }) {
    return {
        id,
        qty: {
            amount: qty.amount,
            currency: qty.currency,
        },
    }
}

export default buildPostApi({
    route: Routes.apiConfirmPaymentIntent,
    mapParams,
    mapPayload,
    buildResult,
})
