import {
    DATETIME_FORMAT_WITH_SECONDS,
} from 'commons/js/constants'
import { parseTimestamp, formatDay } from 'commons/js/util/dateConverter'
import { buildBookingService } from './booking-service'
import { buildVehicle } from './vehicle'

export function mapPrebooking (prebooking) {
    return {
        id_parking: parseInt(prebooking.parkingId, 10),
        id_booking_product: parseInt(prebooking.bookingProductId, 10),
        start: formatDay(parseTimestamp(prebooking.startDate), DATETIME_FORMAT_WITH_SECONDS),
        end: formatDay(parseTimestamp(prebooking.endDate), DATETIME_FORMAT_WITH_SECONDS),
    }
}

export function mapBooking ({ bookingId }) {
    return {
        idBooking: bookingId,
    }
}

export function buildBooking (booking) {
    return {
        id: booking.id,
        idExternal: booking.id_external,
        cancellable: booking.cancellable,
        cancelableLimitDate: booking.cancelableLimitDate,
        canExtend: booking.can_extend,
        startDate: booking.start_date,
        endDate: booking.end_date,
        bookingProduct: booking.bookingProduct,
        bookingInstructions: booking.booking_instructions,
        refunded: booking.refunded,
        priceQty: booking.price_qty,
        priceBaseQty: booking.base_price_no_commission_qty,
        commissionQty: booking.commission_qty,
        parking: booking.parking,
        vehicle: booking.vehicle ? buildVehicle(booking.vehicle) : null,
        bookingAddedServices: booking.bookingAddedServices.map(buildBookingService),
        isPostPayment: booking.isPostPayment,
        bookingPromotionalCode: booking.bookingPromotionalCode,
        promotionalCodePrice: booking.promotional_code_discounted_price,
        canceled: booking.canceled,
        canEdit: booking.can_edit,
        canEntryInAdvance: booking.can_entry_in_advance,
        entryInAdvanceMinutes: booking.entry_in_advance_minutes,
        wallet: booking.wallet && {
            name: booking.wallet.name,
            id: booking.wallet.id,
            isAdmin: booking.wallet.is_admin,
            qty: booking.wallet.qty,
        },
    }
}
