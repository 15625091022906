import Routes from '../routes/routes'
import { buildDelApi } from './helpers'

export default buildDelApi({
    route: Routes.apiDeleteAllCorporateWhitelistedNumberPlates,
    buildResult: () => true,
    mapParams: ({walletId}) => ({
        walletId,
    }),
    mapPayload: () => {},
})
