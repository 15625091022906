import Routes from '../routes/routes'
import { buildGetApi } from './helpers'

function mapParams ({ walletId, numberPlate = null, startDate = null, endDate = null, beneficiaryId = null }) {
    return {
        walletId,
        numberPlate,
        start_date: startDate,
        end_date: endDate,
        beneficiaryId,
    }
}

export default buildGetApi({
    route: Routes.apiSendDownloadMovementsToEmail,
    buildResult: () => true,
    mapParams,
})
