import Routes from '../routes/routes'
import { buildPutApi } from './helpers'
import { buildWorkshop } from './converters/workshopBooking'
function mapParams ({ bookingId }) {
    return { bookingId }
}

function mapPayload ({ id, providerId }) {
    return {
        id_workshop_provider: providerId,
        id_workshop: id,
    }
}

export default buildPutApi({
    route: Routes.apiWorkshopBookingAssignWorkshopPath,
    buildResult: buildWorkshop,
    mapParams,
    mapPayload,
})
