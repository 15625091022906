import {
    DATETIME_FORMAT_WITH_SECONDS,
} from 'commons/js/constants'
import { parseTimestamp, formatDay } from 'commons/js/util/dateConverter'

export function buildBookingProductAvailability (
    bookingProductAvailability
) {
    const { bookingProduct, bookingAvailability } = bookingProductAvailability
    return {
        product: {
            id: bookingProduct.id,
            name: bookingProduct.name,
            description: bookingProduct.description,
            isDefault: bookingProduct.default,
            maxStayMinutes: bookingProduct.max_stay_minutes,
            minStayMinutes: bookingProduct.min_stay_minutes,
            advanceMinutes: bookingProduct.advance_minutes,
        },
        availability: {
            availabilityStatus: bookingAvailability.availabilityStatus,
            availabilityExtraInfo: bookingAvailability.availabilityExtraInfo,
            priceQty: bookingAvailability.price_qty,
        },
        parkingName: bookingProduct.parking.name,
    }
}

export function buildBookingAvailability (bookingAvailability) {
    return bookingAvailability.map(buildBookingProductAvailability)
}

export function mapBookingAvailability ({
    parkingId,
    bookingProductId,
    startDate,
    endDate,
    promoCode,
    services = [],
}) {
    return {
        idParking: parkingId,
        id_booking_product: bookingProductId,
        start: formatDay(
            parseTimestamp(startDate),
            DATETIME_FORMAT_WITH_SECONDS
        ),
        end: formatDay(parseTimestamp(endDate), DATETIME_FORMAT_WITH_SECONDS),
        parking_booking_services: services ? services.join(',') : null,
        promotional_code: promoCode,
    }
}
